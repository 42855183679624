import { AfterViewInit, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { filter, first, Subscription } from 'rxjs';
import { SupplierInvitesService } from '../../../../shared/services/supplier-invites/supplier-invites.service';
import { InvitesListing } from '../../../../shared/models/invites-list.interface';
import { WindowService } from '../../../../shared/services/window/window.service';
import { Store } from '@ngrx/store';
import { appActions } from '../../../../store/actions/app.actions';
import { QuoteModel } from '../../../../shared/models/quotes';
import { ListingService } from '../../../../shared/services/listing/listing.service';
import { Listing } from '../../../../shared/services/listing/interfaces';
import { DialogData, DialogType } from '../../../../shared/models/dialogs';
import { DialogComponent } from '../../../../shared/components/dialogs/dialog-base/dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ScrollStrategyOptions } from '@angular/cdk/overlay';
import { ListingAction } from '../../../suppliers/components/supplier/supplier.component';
import { FormPartialEnum } from '../../../../shared/enums/partial-status.enum';
import { EnhancedFormsLauncherService } from '../../../../shared/services/enhanced-forms-launcher/enhanced-forms-launcher.service';
import { EventType } from '../../../../shared/services/event-request/event-request.type';
import { selectEvent } from '../../../../store/selectors/event.selectors';

@Component({
  selector: 'app-request-invites',
  templateUrl: './invites.component.html',
  styleUrls: ['./invites.component.scss']
})
export class InvitesComponent implements OnInit, AfterViewInit, OnDestroy {
  eventRequestUuid: string;
  eventUuid: string;
  innerHeight: number;
  innerWidth: number;
  invites: QuoteModel[];
  isMobile: boolean;
  loadingInvited: boolean;
  selectedInvite: InvitesListing;
  slideIndex = 1;
  step = 2;
  subscriptionList = new Subscription();
  swipeCoord?: [number, number];
  swipeTime?: number;
  suggestedSupplierAction: ListingAction;

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth;
    this.innerHeight = window.innerHeight;
    this._store.dispatch(appActions.ToggleShowingSidenav({ payload: false }));
    this.isMobile = this._windowService.isMobile();
  }

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _supplierInvitesService: SupplierInvitesService,
    private _store: Store,
    private _windowService: WindowService,
    private _listingService: ListingService,
    public dialog: MatDialog,
    private sso: ScrollStrategyOptions,
    private _enhancedFormsLauncherService: EnhancedFormsLauncherService
  ) {
    this.onResize();
  }

  ngOnInit() {
    this.getRoute();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this._store.dispatch(appActions.ToggleShowingSidenav({ payload: false }));
    }, 0);
  }

  /**
   * Get invites for provided event request
   * @param eventRequestUuid
   */
  getInvites() {
    this._store.dispatch(appActions.IncrementLoadingCount());

    this._supplierInvitesService
      .getInvites(this.eventRequestUuid, 10)
      .pipe(first())
      .subscribe({
        next: data => {
          this._store.dispatch(appActions.DecrementLoadingCount());

          this.invites = data;
        },
        error: err => {
          this._store.dispatch(appActions.DecrementLoadingCount());
          return console.error(err);
        }
      });
  }

  private tryLaunchSupplementaryForm(formStage: FormPartialEnum, partialFormUuid: string, eventUuid: string, event: EventType) {
    const isPartialPending = formStage === FormPartialEnum.PARTIAL_PENDING;
    const hasPartialCompletionFormUuid = !!partialFormUuid;

    if (isPartialPending && hasPartialCompletionFormUuid) {
      const model = this._enhancedFormsLauncherService.getModelFromEvent(event);

      this._enhancedFormsLauncherService.launchForm({ formUuid: partialFormUuid, eventUuid, model });
    }
  }

  private subscribeToEvent(): void {
    this._store.dispatch(appActions.IncrementLoadingCount());
    this._store
      .select(selectEvent.getEvent)
      .pipe(
        filter(event => event !== null),
        first()
      )
      .subscribe({
        next: ({ formStage, event, partialFormUuid }) => {
          this._store.dispatch(appActions.DecrementLoadingCount());

          this.tryLaunchSupplementaryForm(formStage, partialFormUuid, event.uuid, event);
        },
        error: msg => {
          console.error(msg);
          this._store.dispatch(appActions.DecrementLoadingCount());
        }
      });
  }

  /**
   * Get public listing for listing uuid provided
   * @param listing
   * @param listType
   */
  getPublicListing(listing, listType: string) {
    this._store.dispatch(appActions.IncrementLoadingCount());
    let listingUuid: string;

    switch (listType) {
      case 'suggested':
        listingUuid = listing.uuid;
        break;
      case 'interested':
      case 'invited':
        listingUuid = listing.listingUuid;
        break;
    }

    this._listingService
      .getPublicListing(listingUuid)
      .pipe(first())
      .subscribe({
        next: response => {
          this._store.dispatch(appActions.DecrementLoadingCount());
          if (response) {
            this.openSuggestedDialog(response);
          }
          return response;
        },
        error: err => {
          this._store.dispatch(appActions.DecrementLoadingCount());
          console.error('Public listing error: ', err);
        }
      });
  }

  /**
   * Open Suggested dialog and set action type after close
   * @param suggestedSupplier
   */
  private openSuggestedDialog(suggestedSupplier: Listing) {
    const dialogData: DialogData = {
      type: DialogType.SUGGESTED,
      title: 'Suggested',
      showHeader: false,
      record: {
        inviteData: suggestedSupplier,
        inviteHeader: {
          icon: 'ate-info-v2',
          intro: `This is a suggested supplier that matches your event!`,
          main: ` — do you want to invite them to send a quote`
        }
      }
    };

    const dialogRef = this.dialog.open(DialogComponent, {
      width: '1000px',
      height: this.innerWidth > 1023 ? 'auto' : '99vh',
      maxWidth: '100vw',
      hasBackdrop: true,
      backdropClass: 'dialog-backdrop-dark',
      panelClass: 'invite-dialog',
      scrollStrategy: this.sso.noop(),
      data: dialogData
    });

    dialogRef
      .afterClosed()
      .pipe(first())
      .subscribe({
        next: response => {
          if (response) {
            this.suggestedSupplierAction = response;
          }
        },
        error: err => {
          console.error(err);
        }
      });
  }

  /**
   * Extract UUIDs from the route and get invited
   */
  private getRoute() {
    this._activatedRoute.paramMap.pipe(first()).subscribe({
      next: response => {
        this.eventRequestUuid = response.get('eventRequestUuid');
        this.eventUuid = response.get('eventUuid');
        this.getInvites();
        this.subscribeToEvent();
      },
      error: err => console.error(err)
    });
  }

  ngOnDestroy() {
    this.subscriptionList.unsubscribe();
    this._store.dispatch(appActions.ToggleShowingSidenav({ payload: !this.isMobile }));
  }
}

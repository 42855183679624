/* eslint-disable @typescript-eslint/no-explicit-any */
import { Inject, Injectable } from '@angular/core';
import { Observable, filter, first, fromEvent } from 'rxjs';
import { EventType } from '../event-request/event-request.type';
import { FormModel } from './form-model.interface';
import { AuthService } from '../auth/auth.service';
declare global {
  interface Window {
    ateV2OpenForm: (formLaunchConfig: { [key: string]: any }) => void;
  }
}
@Injectable()
export class EnhancedFormsLauncherService {
  private localStorageAuthKey = 'enhancedFormsfirebaseAuthToken';

  constructor(
    @Inject('environment') private environment,
    private _authService: AuthService
  ) {}

  launchForm({ formUuid = '', eventUuid = '', mainEventUuid = '', userUuid = '', postcode = '', model = {} }) {
    this._authService
      .getAuthToken$()
      .pipe(
        first(),
        filter(token => !!token)
      )
      .subscribe({
        next: token => {
          this.saveAuthTokenToLocalStorage(token);

          window.ateV2OpenForm({
            formUuid,
            googlePlacesApiKey: this.environment.googleMapsApiKey,
            eventUuid,
            model,
            mainEventUuid,
            userUuid,
            postcode
          });
        },
        error: console.error
      });
  }

  onFormClose(): Observable<Event> {
    return fromEvent(window, 'onExitForm');
  }

  /**
   * Constructs an object that can be used to pre-populate the fields of the enhanced forms.
   * Can be expanded with the other fields found in the form definitions.
   *
   * @param event The event object that we can use to populate the model.
   */
  getModelFromEvent(event: EventType): FormModel {
    return { ...(event.description && { field_event_body: event.description ?? '' }) };
  }

  /**
   * TODO: Automatically refresh the token in local storage on expiry.
   * @param token The auth token from Firebase.
   */
  private saveAuthTokenToLocalStorage(token: string) {
    console.log(`Auth token set at ${Date.now()}`);
    window.localStorage.setItem(this.localStorageAuthKey, token);
  }
}

import { Component, CUSTOM_ELEMENTS_SCHEMA, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IMainEventData } from '../../../../shared/models/events';
import { Subscription } from 'rxjs';
import { CommonModule } from '@angular/common';
import { EventItemComponent } from '../../event-item/event-item.component';
import { ActivatedRoute } from '@angular/router';
import { EnhancedFormsLauncherService } from '../../../../shared/services/enhanced-forms-launcher/enhanced-forms-launcher.service';

@Component({
  selector: 'app-event-request-dialog',
  templateUrl: './event-request-dialog.component.html',
  styleUrl: './event-request-dialog.component.scss',
  standalone: true,
  imports: [CommonModule, EventItemComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class EventRequestDialogComponent implements OnDestroy {
  events: IMainEventData[];
  subscriptionList: Subscription = new Subscription();

  constructor(
    public dialogRef: MatDialogRef<EventRequestDialogComponent>,
    private _activatedRoute: ActivatedRoute,
    private _enhancedFormsLauncherService: EnhancedFormsLauncherService,
    @Inject(MAT_DIALOG_DATA) public data,
    @Inject('environment') private environment
  ) {}

  receiveEmittedEvent(event: IMainEventData) {
    const { queryParamMap, root } = this._activatedRoute.snapshot;

    const formUuid = queryParamMap.get('form_uuid');
    const geoLocation = queryParamMap.get('geo_location');

    if (event) {
      if (queryParamMap.get('form') && queryParamMap.get('form') === 'open') {
        this.launchForm({ event, formUuid, geoLocation }, root?.queryParams);
      } else {
        this.closeDialog(event);
      }
    }
  }

  launchForm(params: { event: IMainEventData; formUuid?: string; geoLocation?: string }, queryParams: Record<string, string>) {
    const filteredParams = queryParams && Object.fromEntries(Object.entries(queryParams).filter(([, value]) => value != null));

    const { event, formUuid, geoLocation } = params;
    if (event.mainEventUuid) {
      if (formUuid) {
        this._enhancedFormsLauncherService.launchForm({ formUuid, mainEventUuid: event.mainEventUuid, postcode: geoLocation });
      } else {
        window.location.href = `${this.environment.webUrl}/request-quotes?${new URLSearchParams(filteredParams as Record<string, string>).toString()}&mainEventUuid=${event.mainEventUuid}`;
      }
    } else {
      if (formUuid) {
        this._enhancedFormsLauncherService.launchForm({ formUuid, postcode: geoLocation });
      } else {
        window.location.href = `${this.environment.webUrl}/request-quotes?${new URLSearchParams(filteredParams as Record<string, string>).toString()}`;
      }
    }
  }

  closeDialog(data): void {
    this.dialogRef.close(data);
  }

  ngOnDestroy() {
    this.subscriptionList.unsubscribe();
  }
}
